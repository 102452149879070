import { useEffect, useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";

import Banner from "../banner/banner";
import Page from "../page/page";
import ReactGA from "react-ga4";

import "./BookingPage.scss";
import classNames from "classnames";

const BookingPage = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [allEventsForSelectedCity, setAllEventsForSelectedCity] = useState([]);
  const [allCities, setAllCities] = useState([]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Booking Page",
    });
    fetch(
      `https://firestore.googleapis.com/v1/projects/shotam-b276d/databases/(default)/documents/events`,
    )
      .then((res) => {
        res.json().then((events) => {
          // console.log(events);
          setAllEvents(events.documents);
          setAllCities(
            events.documents
              .map((event) => event.fields.city.stringValue)
              .filter((city, index, array) => array.indexOf(city) === index),
          );
        });
      })
      .catch(() => {});
    //   fetch(
    //     `https://firestore.googleapis.com/v1/projects/shotam-b276d/databases/(default)/documents/bookings`,
    //   )
    //     .then((res) => {
    //       res.json().then((bookings) => {
    //         console.log(bookings);
    //       });
    //     })
    //     .catch(() => {});
    //@ts-ignore
    // window.EBWidgets.createWidget({
    //   widgetType: "checkout",
    //   eventId: "1218801972509",
    //   modal: true,
    //   modalTriggerElementId: "eventbrite-widget-modal-trigger-1218801972509",
    //   onOrderComplete: orderCallback,
    // });
    //@ts-ignore
    // window.EBWidgets.createWidget({
    //   widgetType: "checkout",
    //   eventId: "1242104009529",
    //   modal: true,
    //   modalTriggerElementId: "eventbrite-widget-modal-trigger-1242104009529",
    //   onOrderComplete: orderCallback,
    // });
  }, []);
  const [bookingType, setBookingType] = useState("");
  const [bookingLocation, setBookingLocation] = useState("");
  // const [modalSoloInitialized, setModalSoloInitialized] = useState(false);
  // const [modalGroupInitialized, setModalGroupInitialized] = useState(false);
  const [bookingComplete, setBookingComplete] = useState(false);
  const [intializedWidgets, setInitializedWidgets] = useState([0]);

  const handleBookingTypeSelection = (type) => {
    setBookingType(type);
    if (type === "group") {
      setBookingLocation("");
    }
    // if (type === "solo" && !modalSoloInitialized) {
    //   // setModalSoloInitialized(true);
    //   setTimeout(() => {
    //     //  @ts-ignore
    //     window.EBWidgets.createWidget({
    //       widgetType: "checkout",
    //       eventId: "1218801972509",
    //       modal: true,
    //       modalTriggerElementId:
    //         "eventbrite-widget-modal-trigger-1218801972509",
    //       onOrderComplete: orderCallback,
    //     });
    //   }, 100);
    // }
    // // if (type === "group" && !modalGroupInitialized) {
    // //   setModalGroupInitialized(true);
    // // }
  };
  const handleBookingLocationSelection = (e) => {
    setBookingLocation(e.target.value);
    setAllEventsForSelectedCity(
      //@ts-ignore
      allEvents.filter((ev) => ev.fields.city.stringValue === e.target.value),
    );

    allEvents
      //@ts-ignore
      .filter((ev) => ev.fields.city.stringValue === e.target.value)
      .map((ev) => {
        //@ts-ignore
        const eventbriteId = ev.fields.eventbrite_id?.integerValue;
        //@ts-ignore
        if (eventbriteId && !intializedWidgets.includes(eventbriteId)) {
          setInitializedWidgets([...intializedWidgets, eventbriteId]);
          setTimeout(() => {
            //  @ts-ignore
            window.EBWidgets.createWidget({
              widgetType: "checkout",
              eventId: eventbriteId,
              modal: true,
              modalTriggerElementId:
                "eventbrite-widget-modal-trigger-" + eventbriteId,
              onOrderComplete: orderCallback,
            });
          }, 100);
        }
      });
  };

  const orderCallback = function () {
    console.log("Order complete!");
    setBookingComplete(true);
  };
  return (
    <>
      <Page>
        <Banner
          texts={[
            `Les évènements Shotam !`,
            "... en France ...",
            "... et aux alentours !",
          ]}
          style={{ marginTop: "100px" }}
        />
        <div className="bookingExplanation">
          Notre saison d'évènements commence le 29 Mars, et on ira dans pas mal
          de villes en France !
          <select
            style={{ marginTop: 20 }}
            name="location"
            onChange={handleBookingLocationSelection}
          >
            <option value="">Recherche ta ville </option>
            {allCities.map((city) => (
              <option value={city}>{city}</option>
            ))}
            {/* <option value="defense">La Défense</option> */}
          </select>
        </div>
        {/* <div className="bookingExplanation">
          <span className="explanationNumber">1</span>
          <button
            className={classNames(
              "showBookingButton",
              "showBookingButtonAlone",
              bookingType === "solo" ? "selected" : "",
            )}
            onClick={() => handleBookingTypeSelection("solo")}
          >
            Je suis un particulier
          </button>
          <div
            style={{
              fontSize: 20,
              fontWeight: 700,
              width: "100%",
              textAlign: "center",
              margin: "20px 0px",
              fontFamily: "Bungee",
            }}
          >
            OU
          </div>
          <button
            className={classNames(
              "showBookingButton",
              "showBookingButtonGroup",
              bookingType === "group" ? "selected" : "",
            )}
            onClick={() => handleBookingTypeSelection("group")}
          >
            Je suis une entreprise
          </button>
        </div> */}

        {bookingLocation.length > 0 ? (
          <>
            {allEventsForSelectedCity
              //@ts-ignore
              .sort((e1, e2) => {
                //@ts-ignore
                return (
                  //@ts-ignore
                  e1.fields.date_start.timestampValue <
                    //@ts-ignore
                    e2.fields.date_end.timestampValue
                    ? -1
                    : 1
                );
              })
              .map((event) => getBookingLayout(event))}
          </>
        ) : (
          <div className="bookingResult">
            <div className="explanationHint">Pas de résultat à afficher.</div>
          </div>
        )}
        {/* <div className="bookingExplanation">
          <span className="explanationNumber">2</span>
          <div className="explanationHint">
            Il peut y avoir jusqu'à 12 joueurs par partie !
          </div>
          Tu peux inviter tes amis en leur partageant cette page !
          <div className="shareContainer">
            <FacebookShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <TwitterIcon />
            </TwitterShareButton>
            <LinkedinShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <LinkedinIcon />
            </LinkedinShareButton>
            <WhatsappShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <WhatsappIcon />
            </WhatsappShareButton>
          </div>
          <br />
        </div> */}
      </Page>
    </>
  );
};

const isPastEvent = (event) => {
  const today = new Date();
  const date_end = new Date(event.fields.date_end.timestampValue);
  return date_end < today;
};

const getBookingLayout = (event) => {
  const date = new Date(event.fields.date_start.timestampValue);
  const date_end = new Date(event.fields.date_end.timestampValue);

  return (
    <div
      className={classNames(
        "bookingResult",
        isPastEvent(event) ? "pastResult" : "",
      )}
    >
      <div>{event.fields.description.stringValue}</div>
      {event.fields.eventbrite_id && !isPastEvent(event) ? (
        <button
          id={`eventbrite-widget-modal-trigger-${event.fields.eventbrite_id.integerValue}`}
          type="button"
          className="showBookingButton"
        >
          Réserver ma place !
        </button>
      ) : (
        <></>
        // <button type="button" className="showBookingButton" disabled>
        //   Réserver ma place !
        // </button>
      )}

      <div className="bookingResultDate">
        {date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear()}
      </div>
      <div style={{ marginTop: 25 }} className="explanationHint">
        <li>Lieu : {event.fields.city.stringValue} </li>
      </div>
      <div className="explanationHint">
        <li>
          Date de début :{" "}
          {date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear()}{" "}
        </li>
      </div>
      <div className="explanationHint">
        <li>
          Date de fin :{" "}
          {date_end.getDate() +
            "/" +
            (date_end.getMonth() + 1) +
            "/" +
            date_end.getFullYear()}{" "}
        </li>
      </div>

      <div className="explanationHint">
        <li>Confort de jeu idéal pour les + 12 ans</li>
      </div>
    </div>
  );
};
export default BookingPage;
